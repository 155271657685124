import { lazy,Suspense } from 'react';
import './style.css';
import Loading from '../../components/Loading/loading';

const Banner = lazy(() => import("./components/section1"));
const AboutUs = lazy(() => import("./components/section2"));
const Visions = lazy(() => import("./components/section4"));
const VideoPart = lazy(() => import("./components/section3"));
const Blogs = lazy(() => import("./components/section5"));
const Team = lazy(() => import("./components/section6"));
const Contact = lazy(() => import('./components/section7'))


const HomePage = () => {
  return (
    <div className="main">
      <Suspense fallback={<Loading />}>
        <Banner />
        <AboutUs />
        <VideoPart />
        <Visions />
        <Blogs />
        <Team />
        <Contact />
      </Suspense>
    </div>
  );
};

export default HomePage;
