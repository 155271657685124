// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*--------------------reset--------------------------*/
article, aside, audio, canvas, command, datalist, details, embed, figcaption, figure,
footer, header, hgroup, keygen, meter, nav, output, progress, section, source, video, a { display: block }
* { 
    background: none; list-style: none; outline: none; padding: 0; margin: 0; 
    font-weight: normal; box-sizing: border-box; flex-wrap: wrap; border: none; color: inherit;
}
article, aside, audio, canvas, command, datalist, details, embed, figcaption, figure,
footer, header, hgroup, keygen, meter, nav, output, progress, section, source, video, a { display: block }
* &::before,
&::after { 
   box-sizing: border-box; flex-wrap: wrap; 
}
a { text-decoration: none; transition: all ease 0.3s; }
a, input[type=button], input[type=submit], button { cursor: pointer; }
img { max-width: 100%; display:block; }
.main { display: block; margin: 0 auto; }
.container{ display: block; margin: 0 auto;width: 1200px;}
.clear { clear: both; height: 0px; line-height: 0px; overflow: hidden; }
.clearfix:after { content: ''; display: block; height: 0; clear: both; }
.hidden { display: none !important; }
span{display: inline-block}
.swiper-wrapper { display: flex;flex-wrap:nowrap;}



`, "",{"version":3,"sources":["webpack://./src/css/_reset.css"],"names":[],"mappings":"AAAA,sDAAsD;AACtD;0FAC0F,eAAe;AACzG;IACI,gBAAgB,EAAE,gBAAgB,EAAE,aAAa,EAAE,UAAU,EAAE,SAAS;IACxE,mBAAmB,EAAE,sBAAsB,EAAE,eAAe,EAAE,YAAY,EAAE,cAAc;AAC9F;AACA;0FAC0F,eAAe;AACzG;;GAEG,sBAAsB,EAAE,eAAe;AAC1C;AACA,IAAI,qBAAqB,EAAE,yBAAyB,EAAE;AACtD,oDAAoD,eAAe,EAAE;AACrE,MAAM,eAAe,EAAE,aAAa,EAAE;AACtC,QAAQ,cAAc,EAAE,cAAc,EAAE;AACxC,YAAY,cAAc,EAAE,cAAc,CAAC,aAAa,CAAC;AACzD,SAAS,WAAW,EAAE,WAAW,EAAE,gBAAgB,EAAE,gBAAgB,EAAE;AACvE,kBAAkB,WAAW,EAAE,cAAc,EAAE,SAAS,EAAE,WAAW,EAAE;AACvE,UAAU,wBAAwB,EAAE;AACpC,KAAK,qBAAqB;AAC1B,kBAAkB,aAAa,CAAC,gBAAgB,CAAC","sourcesContent":["/*--------------------reset--------------------------*/\narticle, aside, audio, canvas, command, datalist, details, embed, figcaption, figure,\nfooter, header, hgroup, keygen, meter, nav, output, progress, section, source, video, a { display: block }\n* { \n    background: none; list-style: none; outline: none; padding: 0; margin: 0; \n    font-weight: normal; box-sizing: border-box; flex-wrap: wrap; border: none; color: inherit;\n}\narticle, aside, audio, canvas, command, datalist, details, embed, figcaption, figure,\nfooter, header, hgroup, keygen, meter, nav, output, progress, section, source, video, a { display: block }\n* &::before,\n&::after { \n   box-sizing: border-box; flex-wrap: wrap; \n}\na { text-decoration: none; transition: all ease 0.3s; }\na, input[type=button], input[type=submit], button { cursor: pointer; }\nimg { max-width: 100%; display:block; }\n.main { display: block; margin: 0 auto; }\n.container{ display: block; margin: 0 auto;width: 1200px;}\n.clear { clear: both; height: 0px; line-height: 0px; overflow: hidden; }\n.clearfix:after { content: ''; display: block; height: 0; clear: both; }\n.hidden { display: none !important; }\nspan{display: inline-block}\n.swiper-wrapper { display: flex;flex-wrap:nowrap;}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
