import React from "react";
import HomePage from "./screen/home";
import Layout from "./components/Layout/Layout";
import ListNews from "./screen/listNews";
import "./css/style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DetailNews from "./screen/detailNews";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path={"news"} element={<ListNews />} />
            <Route path={"news/:id"} element={<DetailNews />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
    
  );
}

export default App;
