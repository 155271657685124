import React from "react";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { LogoFooter } from "../../../../assets";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="part1">
          <figure className="logo--footer">
            <img src={LogoFooter} alt="logo FIDAO" />
          </figure>
          <div className="newsletter">
            <p>Join our mailing list to stay in the loop with our news</p>
            <div className="form-newsletter">
              <input
                type="text"
                disabled
                className="txt"
                placeholder="Enter your email address..."
                style={{}}
              />
              <button
                style={{
                  filter: "grayscale(1)",
                  opacity: "0.5",
                }}
                className="btn"
              >
                Subscribe{" "}
                <span
                  style={{
                    fontSize: "10px",
                  }}
                >
                  (Coming soon)
                </span>
              </button>
            </div>
          </div>
          <ul className="menu--footer">
            <li>
              <Link to={"/news"}>News</Link>
            </li>
            <li>
              <AnchorLink href="#AboutUs">About Us</AnchorLink>
            </li>
            <li>
              <AnchorLink href="#Vision">Vision</AnchorLink>
            </li>
            <li>
              <AnchorLink href="#Membership">Membership</AnchorLink>
            </li>
          </ul>
        </div>
        <div className="copyRight">
          <span> Copyright © 2023 FIDAO</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
