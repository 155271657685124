import { useState,useEffect,useCallback } from "react";
import { Link } from "react-router-dom"
import Image from "../../components/Image/Image"
import './style.css'
import POSTS from './POST.json'
import {
    blogImg,
    LoadingGif
} from '../../assets'


const ListNews = () => {
    // const [posts,setPosts] = useState([])
    const [loading,setLoading] = useState(false)
    // const [pageCounts,setPageCounts] = useState(0)
    // const [activePageNumber,setActivePageNumber] = useState(1)

    // const loadPosts = async () => {
    //     setLoading(true);
    //     const responsePosts = await fetch('http://www.mocky.io/v2/5e9278be3100005b00462cbd');
    //     const posts = await responsePosts.json()
    //     setPosts(posts);
    //     setLoading(false);
    // };
    // useEffect(() => {
    //     loadPosts();
    // },[])
   
    // ////count pages
    // useEffect(() => {
    //     setPageCounts(calculatePagesCounts()) ;
    // },[posts])


    // const calculatePagesCounts = () =>{
    //     if (posts.length % 3 > 0 ){
    //         return Math.ceil(posts.length / 3 );
    //     }
    //     return Math.ceil(posts.length / 3) ;
    // }
    // const handleClickOnPages = useCallback( (pageNumber) => {
    //     setActivePageNumber(pageNumber)
    // },[])

    return(
        <div className="news-page">
            <section className="page-header">
                <h1>Blog FIDAO</h1>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Blog</li>
                    </ul>
                </nav>
            </section>      
            <div className="container">
                {loading && <div className="loading"><img src={LoadingGif} alt="" /></div>}
                {POSTS.length === 0 && <div> No post</div>}
                {POSTS.length > 0  && (
                    <ul className="list--news">
                        {POSTS.map((item) => (
                            <li key={item.id}>
                                <Image ImgSrc={blogImg} alt='' className="img--news"/>
                                <div className="info--news">
                                    <span className="date">{item.date}</span>
                                    <Link to={`/news/${item.id}`} className='title--news'>
                                        {item.title}
                                    </Link>
                                    <Link to={'/'} className='more'>Read More</Link>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
                
                
                <nav>
                    <ul className="pagination">
                    {/* {new Array(pageCounts).fill(0).map((item,index) => (
                        <li className={activePageNumber === index+1 ? 'page-item active' :  'page-item'}>
                            <button onClick={() => handleClickOnPages(index+1)}>
                                {index+1}
                            </button>
                        </li>
                    ))} */}
                        <li className="page-item active" aria-current="page">
                            <span className="page-link">1</span>
                        </li>
                        <li className="page-item">
                            <Link to={'/'} className='page-link'>2</Link>
                        </li>
                        <li className="page-item">
                            <Link to={'/'} className='page-link'>3</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default ListNews