import Logo from './images/logo.png';
import BannerImg from './images/Banner.webp';
import IconEmail from './icons/email.png'
import IconPhone from './icons/phone.png'
import LogoFooter from './images/logo-footer.webp';
import aboutUsPic from './images/aboutUs.webp';
import visionPic from './images/visionPic.webp';
import visionPic2 from './images/visionPic2.webp';
import visionPic3 from './images/visionPic3.webp';
import visionPic4 from './images/visionPic4.webp';
import VideoImg from './images/video.webp'
import blogImg from './images/blogImg.webp'
import blogImg2 from './images/blogImg2.webp'
import blogImg3 from './images/blogImg3.webp'
import blogImg4 from './images/blogImg4.webp'
import teamImg from './images/teamImg.webp'
import fidaoImg from './images/backSec.png';
import contactImg from './images/contact-FIDAO.webp'
import LoadingGif from './icons/loading.gif';
import BeneImg from './images/Benefits.webp';
import WihtePaperPdf from './pdf/FIDAO_White_Paper.pdf'
export {
    Logo,
    IconEmail,
    IconPhone,
    BannerImg,
    LogoFooter,
    aboutUsPic,
    visionPic,
    visionPic2,
    visionPic3,
    visionPic4,
    VideoImg,
    blogImg,
    blogImg2,
    blogImg3,
    blogImg4,
    teamImg,
    fidaoImg,
    contactImg,
    LoadingGif,
    BeneImg,
    WihtePaperPdf
}  
