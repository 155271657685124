import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Logo, WihtePaperPdf } from "../../../../assets";

const Header = () => {
  const [scroll, setScroll] = useState(0);
  const handleScroll = () => setScroll(document.documentElement.scrollTop);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const classname = scroll > 50 ? "scrolled" : "";
  /**/
  const location = useLocation();
  const menuLink =
    location.pathname === "/" ? (
      <AnchorLink href="#AboutUs">About Us</AnchorLink>
    ) : (
      ""
    );
  const menuLink2 =
    location.pathname === "/" ? (
      <AnchorLink href="#Vision">Vision</AnchorLink>
    ) : (
      ""
    );
  const menuLink3 =
    location.pathname === "/" ? (
      <AnchorLink href="#Membership">Membership</AnchorLink>
    ) : (
      ""
    );

  return (
    <header className={classname}>
      <div className="container">
        <Link to={"/"} className="logo" title="logo FIDAO">
          <img src={Logo} alt="logo FIDAO" />
        </Link>
        <nav>
          <ul className="menu">
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <a href={"https://fidao.org/blog"} target="_blank">
                Blog
              </a>
            </li>
            <li>{menuLink}</li>
            <li>{menuLink2}</li>
            <li>{menuLink3}</li>
          </ul>
        </nav>
        <a
          download={"FIDAO_White_Paper.pdf"}
          className="btn--pdf"
          target="_blank"
          href={WihtePaperPdf}
        >
          White Paper
        </a>
      </div>
    </header>
  );
};

export default Header;
