import { Link } from "react-router-dom"
import {useParams} from "react-router-dom";
import Posts from '../listNews/POST.json'
import './style.css'

const DetailNews = () => {
    const {id} = useParams();
    const news = Posts.find(item => item.id === id);

    return(
        <div className="detail-page"> 
            <section className="page-header">
                <h1>{news.title}</h1>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item"><Link to={'/news'}>News</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{news.title}</li>
                    </ul>
                </nav>
            </section>       
            <div className="container" >
                <span className="date">{news.date}</span>
                <p>
                The European languages are members of the same family. Their separate existence is a myth. For science, music, sport, etc, Europe uses the same vocabulary. The languages only differ in their grammar, their pronunciation and their most common words.

Everyone realizes why a new common language would be desirable: one could refuse to pay expensive translators. To achieve this, it would be necessary to have uniform grammar, pronunciation and more common words.
                </p>
                <figure className="img-news">
                    <img src={news.image} alt="" />
                </figure>
                <p>
                One could refuse to pay expensive translators. To achieve this, it would be necessary to have uniform grammar, pronunciation and more common words.
                </p>
                <p>
                pIf several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual languages. The new common language will be more simple and regular than the existing European languages. It will be as simple as Occidental; in fact, it will be Occidental.

A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame.
                </p>
                

            </div>
        </div>
    )
}


export default DetailNews