// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-page .img-news{
    max-width: 100%;
    height: auto;
    margin: 30px auto;
    display: flex;
    justify-content: center;
}
.detail-page .page-header{
    margin-bottom: 40px;
}
p{
    font-size: 18px;
    color: #2B292A;
    line-height: 30px;
   
}
.date{
    font-size: 18px;
    font-weight: bold;
    color: #848384;
    padding: 10px 0;

}`, "",{"version":3,"sources":["webpack://./src/screen/detailNews/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,cAAc;IACd,iBAAiB;;AAErB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,eAAe;;AAEnB","sourcesContent":[".detail-page .img-news{\n    max-width: 100%;\n    height: auto;\n    margin: 30px auto;\n    display: flex;\n    justify-content: center;\n}\n.detail-page .page-header{\n    margin-bottom: 40px;\n}\np{\n    font-size: 18px;\n    color: #2B292A;\n    line-height: 30px;\n   \n}\n.date{\n    font-size: 18px;\n    font-weight: bold;\n    color: #848384;\n    padding: 10px 0;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
