import {LoadingGif} from '../../assets'

const Loading = () => {
    return(
        <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
            <img src={LoadingGif} alt='' />
        </div>
    ) 
  }

export default Loading